import { useState } from "react";
import {
  DocumentArrowUpIcon,
  DocumentCheckIcon,
} from "@heroicons/react/24/solid";

import { isFileValid } from "../../../Helpers";
import { Spinner } from "../../shared/Spinner";

type ResumeUploadType = {
  cv: string;
  setCV: React.Dispatch<React.SetStateAction<string>>;
  cvFileName: string;
  setCvFileName: React.Dispatch<React.SetStateAction<string>>;
};

export const ResumeUpload = ({
  cv,
  setCV,
  cvFileName,
  setCvFileName,
}: ResumeUploadType) => {
  const [showCVSpinner, setShowCVSpinner] = useState(false);
  const [cvDragging, setCVDragging] = useState(false);

  const handleDragEnter = (
    e: React.DragEvent<HTMLDivElement>,
    eventSetter: Function,
  ) => {
    e.preventDefault();
    eventSetter(true);
  };

  const handleDragLeave = (
    e: React.DragEvent<HTMLDivElement>,
    eventSetter: Function,
  ) => {
    e.preventDefault();
    eventSetter(false);
  };

  const handleDrop = (
    e: React.DragEvent<HTMLDivElement>,
    eventSetter: Function,
    fileSetter: Function,
  ) => {
    e.preventDefault();
    eventSetter(false);

    const droppedFile = e.dataTransfer.files[0];
    if (
      droppedFile !== undefined &&
      droppedFile.name &&
      isFileValid(droppedFile.name)
    ) {
      fileSetter(droppedFile);
    }
  };

  function onCVChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files === null) return;
    const cv = files[0];
    if (cv !== undefined && cv.name && isFileValid(cv.name)) {
      UploadCV(cv);
    }
  }

  function UploadCV(cv: File) {
    if (cv !== null) {
      setShowCVSpinner(true);
      setCV("");
      const reader = new FileReader();
      reader.readAsDataURL(cv);
      reader.onload = async function () {
        const cvData = reader.result?.toString();
        if (cvData === undefined) {
          setShowCVSpinner(false);
          return;
        }
        setCV(cvData.substring(cvData.indexOf(",") + 1));
        setCvFileName(cv.name);
        setShowCVSpinner(false);
      };
    } else {
      setShowCVSpinner(false);
    }
  }

  return (
    <div className="w-full">
      <label
        htmlFor="cover-photo"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Resume <span className="text-red-600">*</span>
      </label>
      <div
        className={`mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 py-2 ${
          cvDragging ? "bg-gray-100" : ""
        }`}
        onDragEnter={(e) => handleDragEnter(e, setCVDragging)}
        onDragLeave={(e) => handleDragLeave(e, setCVDragging)}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => handleDrop(e, setCVDragging, UploadCV)}
      >
        <div className="text-center">
          {showCVSpinner ? (
            <Spinner />
          ) : cv !== "" ? (
            <label htmlFor="cv-upload" className="cursor-pointer">
              <input
                id="cv-upload"
                name="cv-upload"
                type="file"
                className="sr-only"
                onChange={onCVChanged}
                accept=".pdf,.doc,.docx"
              />
              <div className="flex justify-center px-2">
                <DocumentCheckIcon
                  className="h-10 w-10 text-green-700"
                  aria-hidden="true"
                />
                <strong className="text-xs md:text-sm font-semibold my-auto leading-5 text-gray-600">
                  {cvFileName}
                </strong>
              </div>
            </label>
          ) : (
            <div className="flex gap-2">
              <DocumentArrowUpIcon
                className="mx-auto h-10 w-10 text-gray-300"
                aria-hidden="true"
              />
              <div>
                <div className="mt- px-2 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="cv-upload"
                    className="relative cursor-pointer rounded-md "
                  >
                    <p className="text-center font-normal">
                      <span className="text-entntblue font-semibold focus-within:outline-none focus-within:ring-2 focus-within:ring-entntorange focus-within:ring-offset-2 hover:text-entntorange">
                        Upload a file
                      </span>
                      &nbsp;or drag and drop
                      <span className="text-xs block leading-5">
                        .pdf, .doc up to 10MB
                      </span>
                    </p>
                    <input
                      id="cv-upload"
                      name="cv-upload"
                      type="file"
                      className="sr-only"
                      onChange={onCVChanged}
                      accept=".pdf,.doc,.docx"
                    />
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
