import { useState } from "react";
import { City } from "../Models";

const useCareerTimelineState = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDOB] = useState<Date | null>(null);
  const [email, setEmail] = useState("");
  const [cv, setCV] = useState("");
  const [workLocations, setWorkLocations] = useState<City[]>([]);
  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [cvFileName, setCvFileName] = useState("");
  const [initialEmail, setInitialEmail] = useState<string>();
  const [isCorrectAge, setIsCorrectAge] = useState<boolean>(false);

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    dob,
    setDOB,
    email,
    setEmail,
    cv,
    setCV,
    workLocations,
    setWorkLocations,
    linkedinProfile,
    setLinkedinProfile,
    cvFileName,
    setCvFileName,
    initialEmail,
    setInitialEmail,
    isCorrectAge,
    setIsCorrectAge,
  };
};

export default useCareerTimelineState;
