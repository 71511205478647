import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

import {
  calculatePeriodOfEducation,
  calculatePeriodOfExperience,
  getGapsWithAdjacentEntries,
  getOverlappingEntries,
  hasCareerGap,
  hasNoCurrentExperiences,
  hasOverlappingEducation,
  hasOverlappingExpinEdu,
} from "../../Helpers";
import useIdToken from "../../hooks/useIdToken";
import { useParams } from "react-router-dom";
import useExperienceState from "../../hooks/useExperienceState";
import { RadioButtons } from "./RadioButtons";
import useEducationState from "../../hooks/useEducationState";
import { TimelineCandidateEducation } from "../../Models";
import OverlappingExperiences from "./OverlappingExperiences";
import { CareerGap } from "./CareerGap";
import { CheckBox } from "./CheckBox";

type props = {
  type: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  timelineData: any;
  setOpenDeclaration: React.Dispatch<React.SetStateAction<boolean>>;
  onEditSave: () => Promise<void>;
  experienceState: ReturnType<typeof useExperienceState>;
  educationState: ReturnType<typeof useEducationState>;
  setHasOverlappingEdu: React.Dispatch<React.SetStateAction<boolean>>;
  dob: Date | null;
  setShowCV: React.Dispatch<React.SetStateAction<boolean>>;
};

type GapDescription = {
  descId: number;
  description: string;
  type: string;
  overlappingExp?: TimelineCandidateEducation[];
  gaps?: [
    {
      gapEntry: { totalMonths: number; gap: boolean };
      previousEntry: any;
      nextEntry: any;
    },
  ];
};

export default function OverlappingGapConfirmModal(props: props) {
  const cancelButtonRef = useRef(null);
  const { id } = useParams<{ id: string }>();

  const danger = props.type === "danger";
  const [descriptionList, setDescriptionList] = useState<GapDescription[]>();
  const idToken = useIdToken();
  const [currentPosition, setCurrentPosition] = useState("");
  const { experience, setExperience } = props.experienceState;
  const { education, setEducation } = props.educationState;
  const [isOverlapCorrect, setIsOverlapCorrect] = useState<boolean>(false);
  const [isGapCorrect, setIsGapCorrect] = useState<boolean>(false);
  const storedDate = localStorage.getItem("currentDate");
  const currentDate = storedDate && new Date(storedDate);

  const hasNoCurrExp: boolean = !idToken && hasNoCurrentExperiences(experience);
  const hasOverlapExpinEdu: boolean = hasOverlappingExpinEdu(
    props.timelineData,
  );
  const hasGap: boolean = hasCareerGap(props.timelineData);
  const isCurrentlyStudent: boolean = education.some(
    (edu) => edu.endDate && edu.endDate >= new Date(currentDate!),
  );

  useEffect(() => {
    let updatedDescriptionList = [];

    if (experience.length > 0 && !isCurrentlyStudent && hasNoCurrExp) {
      updatedDescriptionList.push({
        descId: 1,
        description: "You haven't mentioned your current position.",
        type: "hasNoCurrExp",
      });
    }
    if (hasOverlapExpinEdu) {
      updatedDescriptionList.push({
        descId: 2,
        description:
          "It appears that you started working while you were studying, and this overlapped with your education.",
        type: "hasOverlap",
        overlappingExp: getOverlappingEntries(props.timelineData, experience),
      });
    }
    const gaps = getGapsWithAdjacentEntries(props.timelineData);

    if (hasGap) {
      updatedDescriptionList.push({
        descId: 3,
        description: "There are gaps in your career history.",
        type: "hasGap",
        gaps: gaps,
      });
    }
    setDescriptionList(updatedDescriptionList);
  }, [
    hasOverlapExpinEdu,
    hasGap,
    hasNoCurrExp,
    experience,
    props.timelineData,
  ]);

  const sortedExp = experience.sort((a: any, b: any) => {
    if (a.startDate && b.startDate) {
      return a.startDate.getTime() - b.startDate.getTime();
    }
    return 0;
  });

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    if (hasOverlappingEducation(education)) {
      props.setHasOverlappingEdu(true);
      return;
    }
    if (hasNoCurrExp && currentPosition.trim() === "lastposition") {
      const updatedExperience = sortedExp.map((exp, index) => {
        if (index === experience.length - 1) {
          return { ...exp, endDate: null, currentlyWorking: true };
        }
        return exp;
      });
      setExperience(updatedExperience);
    }

    props.setOpen(false);
    if (id) props.setShowCV(true);
    else props.onEditSave();
  };

  const handleEduExpChange = (
    entryType: "experience" | "education",
    field: "startDate" | "endDate",
    value: Date | null,
    id: string,
  ) => {
    if (entryType === "experience") {
      const idx = experience.findIndex((e) => e.id === id);
      let updatedExperience = [...experience];
      updatedExperience[idx] = {
        ...updatedExperience[idx],
        [field]: value,
      };

      if (updatedExperience[idx].startDate && updatedExperience[idx].endDate) {
        updatedExperience[idx].period = calculatePeriodOfEducation(
          updatedExperience[idx].startDate!,
          updatedExperience[idx].endDate!,
        );
      }
      setExperience(updatedExperience);
    } else {
      const idx = education.findIndex((e) => e.id === id);
      const updatedEducation = [...education];
      updatedEducation[idx] = {
        ...updatedEducation[idx],
        [field]: value,
      };

      if (updatedEducation[idx].startDate && updatedEducation[idx].endDate) {
        updatedEducation[idx].period = calculatePeriodOfExperience(
          updatedEducation[idx].startDate!,
          updatedEducation[idx].endDate,
        );
      }
      setEducation(updatedEducation);
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          document.getElementById("hasoverlappingedu-modal")?.id !==
            "hasoverlappingedu-modal" && props.setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500  bg-opacity-50 transition-opacity " />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-7xl ">
                <div className="bg-white p-4 shadow-md">
                  <div className="sm:flex sm:items-center">
                    <div
                      className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                        danger ? "bg-red-100" : "bg-blue-100"
                      } sm:mx-0 `}
                    >
                      <ExclamationTriangleIcon
                        className={`h-6 w-6 ${
                          danger ? "text-red-600" : "text-blue-600"
                        }`}
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt- text-center sm:ml-3 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 text-center "
                      >
                        {props.title}
                      </Dialog.Title>
                    </div>
                  </div>
                </div>
                <div className="bg-white px-12 mt-1 pb-4 flex flex-col items-center overflow-y-auto max-h-[70vh]">
                  <div className="text-sm font-medium text-gray-500 space-y-4 pt-3">
                    <div className="space-y-2 text-black bg-gray-100 p-3">
                      <span className="inline-block">
                        We collect and analyze statistics from the data you
                        provide, so it's crucial that your information is
                        accurate. On this screen, you'll see a summary of your
                        work experience, including any career gaps or
                        overlapping periods, such as working while studying.
                      </span>
                      <span className="inline-block">
                        These entries are flagged for review, not because
                        there's necessarily a mistake, but because they may be
                        higher risk for potential errors. We just want to
                        double-check these details to ensure everything is
                        correct.
                      </span>
                      <span className="inline-block">
                        If the information you've entered is accurate, there's
                        nothing more you need to do. Simply review the details
                        and proceed. This is just a cross-check to confirm that
                        no data entry mistakes have occurred.
                      </span>
                      <span className="inline-block">
                        Thank you for helping us maintain the accuracy of our
                        records.
                      </span>
                    </div>
                    {descriptionList?.map((desc) => (
                      <li
                        key={desc.descId}
                        className="list-decimal pt-4 text-black font-medium"
                      >
                        {desc.description}
                        {desc.type === "hasNoCurrExp" &&
                          experience.length > 0 && (
                            <>
                              <span className="text-red-600">*</span>
                              <div className="text-xs">
                                <RadioButtons
                                  id="unemployed"
                                  label="Currently Unemployed."
                                  value="unemployed"
                                  selectedValue={currentPosition}
                                  setSelectedValue={setCurrentPosition}
                                />
                                <RadioButtons
                                  id="current-position"
                                  label={`Last position is my current position(${
                                    sortedExp[sortedExp.length - 1].company.name
                                  }).`}
                                  value="lastposition"
                                  selectedValue={currentPosition}
                                  setSelectedValue={setCurrentPosition}
                                />
                              </div>
                            </>
                          )}
                        {desc.type === "hasOverlap" && (
                          <div className="space-y-2 pt-3">
                            <CheckBox
                              id="overlap"
                              label="By checking this box, you acknowledge that all details are correct."
                              checked={isOverlapCorrect}
                              setState={setIsOverlapCorrect}
                            />
                            <OverlappingExperiences
                              handleEduExpChange={handleEduExpChange}
                              overlappingExp={desc.overlappingExp}
                              isOverlapCorrect={isOverlapCorrect}
                              dob={props.dob}
                            />
                          </div>
                        )}
                        {desc.type === "hasGap" && desc.gaps && (
                          <div className="space-y-2 pt-3">
                            <CheckBox
                              id="career-gap"
                              label="By checking this box, you acknowledge that all details are correct."
                              checked={isGapCorrect}
                              setState={setIsGapCorrect}
                            />
                            <CareerGap
                              gaps={desc.gaps}
                              handleEduExpChange={handleEduExpChange}
                              isGapCorrect={isGapCorrect}
                            />
                          </div>
                        )}
                      </li>
                    ))}
                    <p className="pt-4">
                      If everything is correct according to your knowledge,
                      please continue to submit. If not, please go back and
                      edit.
                    </p>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex flex-col sm:flex-row items-center gap-4 sm:gap-0 sm:px-6 border-t">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mx-4 bg-entntorange text-white "
                    onClick={(e) => {
                      e.preventDefault();
                      props.setOpen(false);
                    }}
                  >
                    Go back
                  </button>
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  mx-4 bg-entntblue text-white 
                      ${
                        experience.length > 0 &&
                        !isCurrentlyStudent &&
                        hasNoCurrExp &&
                        currentPosition.trim() === "" &&
                        "cursor-not-allowed"
                      }
                      `}
                    onClick={(e) => handleSubmit(e)}
                    disabled={
                      experience.length > 0 &&
                      !isCurrentlyStudent &&
                      hasNoCurrExp &&
                      currentPosition.trim() === ""
                    }
                  >
                    Continue to Submit
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
