import React, { useEffect, useState } from "react";
import {
  Candidate,
  City,
  Company,
  Education,
  Experience,
  University,
} from "../../../Models";
import {
  GetCities,
  PostCandidate,
  PostCity,
  PostCompany,
  PostUniversity,
} from "../../../WebCalls";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../shared/ConfirmModal";
import useIdToken from "../../../hooks/useIdToken";
import { transformExperienceData } from "../../../Helpers";

type props = {
  candidate: Candidate;
  roleId: string;
  setOpenDeclaration: React.Dispatch<React.SetStateAction<boolean>>;
  openDeclaration: boolean;
  openError: boolean;
  setOpenError: React.Dispatch<React.SetStateAction<boolean>>;
  isExceedingMaxGap: boolean;
  setIsExceedingMaxGap: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function ApplyToRole({
  candidate,
  roleId,
  openDeclaration,
  setOpenDeclaration,
  openError,
  setOpenError,
  isExceedingMaxGap,
  setIsExceedingMaxGap,
}: props) {
  const [cities, setCities] = useState<City[]>([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [existingCandidate, setExistingCandidate] = useState(false);
  const navigate = useNavigate();
  const idToken = useIdToken();

  useEffect(() => {
    GetCities().then((data) => setCities(data));
  }, []);

  let allUniversities: University[] = [];
  let allCompanies: Company[] = [];
  let eduexpCities: City[] = [];

  [...candidate.education, ...candidate.experience].forEach(
    (career: Education | Experience) => {
      if ("university" in career) {
        allUniversities.push(career?.university);
        career?.university.city && eduexpCities.push(career?.university.city);
      } else {
        allCompanies.push(career?.company);
        career?.company.city && eduexpCities.push(career?.company.city);
      }
    },
  );

  async function onSubmit() {
    setOpenDeclaration(false);

    let yearsOfExp = 0;
    candidate.experience.forEach((exp) => {
      if (exp.startDate && exp.endDate) {
        const diffYears = Math.abs(
          exp.endDate.getFullYear() - exp.startDate.getFullYear(),
        );
        yearsOfExp += diffYears;
      }
    });

    const currentWorkLocations1 = cities.find(
      (city) => city.id === candidate.workLocation1CityId,
    );
    const currentWorkLocations2 = cities.find(
      (city) => city.id === candidate.workLocation2CityId,
    );
    const workLocations = [currentWorkLocations1, currentWorkLocations2].filter(
      (location): location is City => location !== undefined,
    );

    try {
      if ([...eduexpCities, ...workLocations].length !== 0) {
        await PostCity([...eduexpCities, ...workLocations]);
      }
      if (allUniversities.length !== 0) {
        await PostUniversity(allUniversities);
      }
      if (allCompanies.length !== 0) {
        await PostCompany(allCompanies);
      }
      const tc: any = {
        ...candidate,
        experience: await transformExperienceData(candidate.experience),
      };
      await PostCandidate(tc, roleId);
      setOpenSuccess(true);
    } catch (error) {
      setExistingCandidate(true);
    } finally {
      setTimeout(() => {
        if (!idToken) navigate("/");
        else navigate("/candidate");
      }, 5000);
    }
  }
  const modalFields = [
    {
      modalId: "declaration-modal",
      type: "info",
      open: openDeclaration,
      setOpen: setOpenDeclaration,
      title: "Declaration",
      descriptionList: [
        {
          descId: 1,
          description:
            "I hereby submit my application to work as a contractor with ENTNT and acknowledge that this submission does not establish an employer-employee relationship with ENTNT and does not create any liability, responsibility, or commitment on behalf of ENTNT.",
        },
        {
          descId: 2,
          description:
            "I affirm that the information I have provided in this application is accurate and complete. I understand that any misrepresentation may lead to the termination of my contract without prior notice.",
        },
        {
          descId: 3,
          description:
            "I am aware that ENTNT may use the information I have supplied at its sole discretion for the purpose of conducting background checks before entering into a contract agreement with me.",
        },
        {
          descId: 4,
          description:
            "I hereby grant ENTNT the irrevocable and unconditional authorization to verify and obtain, as necessary, any information related to me during the application and, if applicable, contracting process, at its sole discretion.",
        },
      ],
      buttonContents: [
        {
          buttonId: 1,
          buttonLabel: "Submit",
          buttonAction: onSubmit,
        },
        { buttonId: 2, buttonLabel: "Cancel", isOutline: true },
      ],
    },
    {
      modalId: "success-modal",
      type: "info",
      open: openSuccess,
      setOpen: setOpenSuccess,
      title: "Application successful",
      description:
        "Your application has been submitted successfully! You will receive an email with further instructions soon.",
      buttonContents: [
        {
          buttonId: 1,
          buttonLabel: `Go to ${idToken ? "applications" : "careers home"}`,
          buttonNavigation: idToken ? "/candidate" : "/",
        },
      ],
    },
    {
      modalId: "existing-candidate-modal",
      type: "danger",
      open: existingCandidate,
      setOpen: setExistingCandidate,
      title: "Applicant Status",
      description:
        "Thank you for your interest! You've already applied for this role.",
      buttonContents: [
        { buttonId: 1, buttonLabel: "Go back to home", buttonNavigation: "/" },
      ],
    },
    {
      modalId: "error-modal",
      type: "danger",
      open: openError,
      setOpen: setOpenError,
      title: "Missing required information",
      description: ` "Please make sure you have filled in all mandatory fields (marked with a red asterisk *)"
          `,
      buttonContents: [
        {
          buttonId: 1,
          buttonLabel: "Go to profile",
          buttonNavigation: "/candidate/profile",
        },
      ],
    },
    {
      modalId: "check-max-gap-modal",
      type: "danger",
      open: isExceedingMaxGap,
      setOpen: setIsExceedingMaxGap,
      title: "Eligibility Notification",
      description: `Thank you for your interest. After reviewing your profile, we found that it does not meet our eligibility requirements for career progression. 
      We appreciate your understanding and encourage you to consider future opportunities with us.`,
      buttonContents: [
        { buttonId: 1, buttonLabel: "Go back to home", buttonNavigation: "/" },
      ],
    },
  ];

  return (
    <>
      {modalFields.map((modal) => (
        <ConfirmModal
          key={modal.modalId}
          modalId={modal.modalId}
          type={modal.type}
          open={modal.open}
          setOpen={modal.setOpen}
          title={modal.title}
          descriptionList={modal.descriptionList}
          description={modal.description}
          buttonContents={modal.buttonContents}
        />
      ))}
    </>
  );
}
